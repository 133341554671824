class Settings {
    static api = {
      'path': process.env.NODE_ENV === 'production' ? 'https://plato.sustainablife.online' : 'http://emerald:8080',
      'authenticationToken': 'plato!inOcean24'
    }

    static tempCategories = [   
            { 'label': 'plastics (mix.)', 'value': '0' },
        ]
}

exports.Settings = Settings;